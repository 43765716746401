/* CSS HEX */
:root {--maroon: #800000ff;
--off-red-rgb: #F30000ff;
--tangelo: #FF4700ff;
--black: #000000ff;
--black-2: #000000ff;
    }
html {
    min-height: 100%;
}
a {
  color: #ff3464;
}
a:hover {
   color: #ff3464;
}
body {
    height:100%;
}
